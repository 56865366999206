import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import jwt from 'jwt-decode'
import fernet from 'fernet'
import moment from 'moment'
const baseurl = process.env.REACT_APP_API_URL

const AxiosAuth = axios.create({
  withCredentials: true,
  baseURL: baseurl,
  headers: { "Content-Type": "application/json" }
})
const AxiosInstance = axios.create({
  withCredentials: true,
  baseURL: baseurl,
  headers: { "Content-Type": "application/json" }
})

let cancelTokenSource
let isRefreshTokenRequest = false
//import { handleLogin, handleLogout } from '../../../redux/authentication'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }
  // ** For Refreshing  Token
  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    // ** Request Interceptor
    AxiosAuth.interceptors.request.use(
      async config => {
        const accessToken = this.getToken()
        if (accessToken) {
          const decode_token = jwt(accessToken)
          const tokenExp = Number(decode_token.exp) > (moment().unix() + 120)
          if (tokenExp) {
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
            if (config.method === 'get') {
              const queryParams = new URLSearchParams(config.url.split('?')[1])
              const paramsObject = Object.fromEntries(queryParams.entries())
              if (paramsObject?.query) {
                cancelTokenSource = axios.CancelToken.source()
                config.cancelToken = cancelTokenSource.token
              }
            }
            return config
          } else {
            try {
              if (isRefreshTokenRequest) {
                this.handleCancelRequest()
              }
              isRefreshTokenRequest = true
              const resp = await this.refreshToken()
              if (resp && resp.status === 200) {
                this.setToken(resp.data.accessToken)
                this.setRefreshToken(resp.data.refreshToken)
                isRefreshTokenRequest = false
                config.headers.Authorization = `${this.jwtConfig.tokenType} ${resp.data.accessToken}`
                return config
              }
            } catch {
              localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
              localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
              window.location.href = "/login"
              return config
            }
          }
        }
      },
      error => {
        console.log("error", error)
        Promise.reject(error)
      }
    )

    AxiosAuth.interceptors.response.use(
      response => response,
      error => {
        if ("Req was canceled" === error.message) {
          return
        }
        if (error.response.status === 400 && error.response.data[0] === "Subscription is Cancelled.") {
          window.location.href = "/subscription"
          return error
        }

        if (error.response.status === 401) {
          console.log("Error Time:", moment().format('YYYY-MM-DD HH:mm:ss'))
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
          window.location.href = "/login"
        }
        Promise.reject(error)
      }
    )
  }

  decodeUserToken = (token) => {
    if (token) {
      try {
        const key = process.env.REACT_APP_FERNET_KEY
        const secret = new fernet.Secret(key)
        const FERNET = new fernet.Token({
          secret,
          token,
          ttl: 0
        })
        return FERNET.decode()
      } catch (err) {
        console.log("error invalid token")
        return null
      }
    }
    return null
  }


  handleCancelRequest = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Req was canceled')
    }
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }


  getToken() {
    return this.decodeUserToken(localStorage.getItem(this.jwtConfig.storageTokenKeyName))
  }

  getRefreshToken() {
    return this.decodeUserToken(localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName))
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return AxiosInstance.post(this.jwtConfig.loginEndpoint, ...args)
  }

  registerOtpApi(...args) {
    return AxiosInstance.post(this.jwtConfig.registerOtpEndpoint, ...args)
  }

  registerApi(...args) {
    return AxiosInstance.post(this.jwtConfig.registerEndpoint, ...args)
  }

  forgotPasswordSendOtpApi(...args) {
    return AxiosInstance.post(this.jwtConfig.forgotPasswordOtpEndpoint, ...args)
  }

  forgotPasswordVerifyOtpApi(...args) {
    return AxiosInstance.put(this.jwtConfig.forgotPasswordOtpEndpoint, ...args)
  }

  resetPasswordApi(...args) {
    return AxiosInstance.post(this.jwtConfig.resetPasswordEndpoint, ...args)
  }


  refreshToken() {
    return AxiosInstance.post(this.jwtConfig.refreshEndpoint, {
      refresh: this.getRefreshToken()
    })
  }

  // All Authorized API

  ChatListApi(page = 1, page_size = 25, chat_type = "All") {
    return AxiosAuth.get(`${this.jwtConfig.chatListApiEndpoint}?page=${page}&page_size=${page_size}&chat_type=${chat_type}`)
  }

  UserAuthCheckApi() {
    return AxiosAuth.get(this.jwtConfig.authCheckApiEndpoint)
  }

  ChatLogsApi(id, page = 1, page_size = 25) {
    return AxiosAuth.get(`${this.jwtConfig.chatLogsApiEndpoint}${id}?page=${page}&page_size=${page_size}`)
  }


  ChatLogsCreateApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.chatLogsCreateApiEndpoint}`, data, { headers: { "Content-Type": "multipart/form-data" } }
    )
  }


  SearchNewMessageApi(query = '') {
    return AxiosAuth.get(`${this.jwtConfig.newChatApiEndpoint}?query=${query}`)
  }

  ValidateNewMessageApi(phone) {
    return AxiosAuth.get(`${this.jwtConfig.newChatApiEndpoint}/${phone}`)
  }

  CreateNewMessageApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.newChatApiEndpoint}`, data)
  }

  ChatLogsReadApi(id) {
    return AxiosAuth.get(`${this.jwtConfig.chatLogsReadApiEndpoint}${id}`)
  }

  ChatResolveApi(data) {
    return AxiosAuth.post(this.jwtConfig.chatLogsResolveApiEndpoint, data)
  }

  SearchContactMessageApi(page = 1, page_size = 25, id, query) {
    return AxiosAuth.get(`${this.jwtConfig.serachContactMessageApiEndpoint}${id}?page=${page}&page_size=${page_size}&query=${query}`)
  }

  SearchChatApi(page = 1, page_size = 25, chat_type = "All", query = '', chat_more = true, msg_more = true) {
    return AxiosAuth.get(`${this.jwtConfig.serachChatApiEndpoint}${chat_type}?page=${page}&page_size=${page_size}&query=${query}&chat_more=${chat_more}&msg_more=${msg_more}`)
  }

  TemplateListApi(page = 1, page_size = 25, query = '', status = '') {
    return AxiosAuth.get(`${this.jwtConfig.TemplateApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}&status=${status}`)
  }

  GetTemplateApi(id) {
    return AxiosAuth.get(`${this.jwtConfig.TemplateApiEndpoint}/${id}`)
  }


  DeleteTemplateApi(id) {
    return AxiosAuth.delete(`${this.jwtConfig.TemplateApiEndpoint}/${id}`)
  }

  CreateTemplateMessageApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.TemplateApiEndpoint}`, data, { headers: { "Content-Type": "multipart/form-data" } }
    )
  }

  UpdateTemplateMessageApi(data) {
    return AxiosAuth.put(`${this.jwtConfig.TemplateApiEndpoint}`, data, { headers: { "Content-Type": "multipart/form-data" } }
    )
  }

  UpdateBusinessSettingsApi(data) {
    return AxiosAuth.put(`${this.jwtConfig.businessSettingsApiEndpoint}`, data)
  }

  // contact api service 
  ContactListApi(page = 1, page_size = 25, query = '') {
    return AxiosAuth.get(`${this.jwtConfig.contactApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}`)
  }

  ContactCreateApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.contactApiEndpoint}`, data)
  }
  ContactUpdateApi(data) {
    return AxiosAuth.put(`${this.jwtConfig.contactApiEndpoint}`, data)
  }
  ContactDeleteApi(id) {
    return AxiosAuth.delete(`${this.jwtConfig.contactApiEndpoint}/${id}`
    )
  }

  // broadcast api service
  BroadcastListApi(page = 1, page_size = 25, query = '', status = '', is_api_cast = true) {
    return AxiosAuth.get(`${this.jwtConfig.broadcastApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}&status=${status}&is_api_cast=${is_api_cast}`)
  }

  BroadcastApi(id) {
    return AxiosAuth.get(`${this.jwtConfig.broadcastApiEndpoint}/${id}`)
  }
  BroadcastCreateApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.broadcastApiEndpoint}`, data)
  }

  BroadcastUpdateApi(data) {
    return AxiosAuth.put(`${this.jwtConfig.broadcastApiEndpoint}`, data)
  }

  BroadcastDeleteApi(id) {
    return AxiosAuth.delete(`${this.jwtConfig.broadcastApiEndpoint}/${id}`)
  }

  BroadcastContactSearchListApi(query = '') {
    return AxiosAuth.get(`${this.jwtConfig.broadcastContactSearchApiEndpoint}?query=${query}`)
  }  
  
  BroadcastDefaultContactTagApi() {
    return AxiosAuth.get(`${this.jwtConfig.broadcastDefaultContactTagApiEndpoint}`)
  }

  BroadcastContactDataListApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.broadcastContactSearchApiEndpoint}`, data)
  }

  // new user 
  AddUserApi(...args) {
    return AxiosAuth.post(this.jwtConfig.newuserApiEndpoint, ...args)
  }
  UpdateUserApi(...args) {
    return AxiosAuth.put(this.jwtConfig.newuserApiEndpoint, ...args)
  }
  UserListApi(page = 1, page_size = 25, query = '') {
    return AxiosAuth.get(`${this.jwtConfig.newuserApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}`)
  }

  // assign_chat

  AddAssignChatApi(...args) {
    return AxiosAuth.post(this.jwtConfig.assignchatApiEndpoint, ...args)
  }

  GetAssignChatApi() {
    return AxiosAuth.get(`${this.jwtConfig.assignchatApiEndpoint}`)
  }

  // Take over chat
  AddTakeoverChatApi(...args) {
    return AxiosAuth.post(this.jwtConfig.takeoverchatApiEndpoint, ...args)
  }

  UpdateTakeoverChatApi(...args) {
    return AxiosAuth.put(this.jwtConfig.takeoverchatApiEndpoint, ...args)
  }

  GetTakeoverChatApi() {
    return AxiosAuth.get(`${this.jwtConfig.takeoverchatApiEndpoint}`)
  }

  //block and important
  BlockChatApi(...args) {
    return AxiosAuth.put(this.jwtConfig.chatBlockApiEndpoint, ...args)
  }

  ImportantChatApi(...args) {
    return AxiosAuth.put(this.jwtConfig.chatImportantApiEndpoint, ...args)
  }

  // tag api

  GetQuickResponseApi(page = 1, page_size = 25, query = '') {
    return AxiosAuth.get(`${this.jwtConfig.quickresponseApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}`)
  }

  CreateQuickResponseApi(data) {
    return AxiosAuth.post(this.jwtConfig.quickresponseApiEndpoint, data, { headers: { "Content-Type": "multipart/form-data" } })
  }

  UpdateQuickResponseApi(data) {
    return AxiosAuth.put(this.jwtConfig.quickresponseApiEndpoint, data, { headers: { "Content-Type": "multipart/form-data" } })
  }
  DeleteQuickResponseApi(id) {
    return AxiosAuth.delete(`${this.jwtConfig.quickresponseApiEndpoint}/${id}`)
  }

  GetTagApi(page = 1, page_size = 25, query = '') {
    return AxiosAuth.get(`${this.jwtConfig.tagApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}`)
  }

  GetAllTagApi() {
    return AxiosAuth.get(`${this.jwtConfig.tagApiEndpoint}`)
  }

  CreateTagApi(...args) {
    return AxiosAuth.post(this.jwtConfig.tagApiEndpoint, ...args)
  }
  AddTagApi(...args) {
    return AxiosAuth.put(this.jwtConfig.tagApiEndpoint, ...args)
  }
  RemoveTagApi(...args) {
    return AxiosAuth.patch(this.jwtConfig.tagApiEndpoint, ...args)
  }

  DeleteTagApi(tag_id) {
    return AxiosAuth.delete(`${this.jwtConfig.tagApiEndpoint}/${tag_id}`)
  }

  // webhook api
  GetWebhookEventApi(page = 1, page_size = 25, query = '') {
    return AxiosAuth.get(`${this.jwtConfig.webhookEventApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}`)
  }

  GetWebhookApi(page = 1, page_size = 25, query = '') {
    return AxiosAuth.get(`${this.jwtConfig.webhookApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}`)
  }
  CreateWebhookApi(...args) {
    return AxiosAuth.post(this.jwtConfig.webhookApiEndpoint, ...args)
  }
  UpdateWebhookApi(...args) {
    return AxiosAuth.put(this.jwtConfig.webhookApiEndpoint, ...args)
  }
  DeleteWebhookApi(webhook_id) {
    return AxiosAuth.delete(`${this.jwtConfig.webhookApiEndpoint}/${webhook_id}`)
  }
  ApiKeyApi() {
    return AxiosAuth.get(this.jwtConfig.apikeyApiEndpoint)
  }

  DashboardApi() {
    return AxiosAuth.get(this.jwtConfig.dashboardApiEndpoint)
  }

  UserProfileApi() {
    return AxiosAuth.get(this.jwtConfig.userProfileApiEndpoint)
  }
  UpdateUserProfileApi(data) {
    return AxiosAuth.put(this.jwtConfig.userProfileApiEndpoint, data, { headers: { "Content-Type": "multipart/form-data" } })
  }
  // notification
  GetNotificationApi(page = 1, page_size = 25, query = '') {
    return AxiosAuth.get(`${this.jwtConfig.notificationApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}`)
  }

  ReadNotificationApi(...args) {
    return AxiosAuth.put(this.jwtConfig.notificationApiEndpoint, ...args)
  }
  /// Analytics
  AnalyticsApi(from_date = '', to_date = '') {
    return AxiosAuth.get(`${this.jwtConfig.messageAnalyticsApiEndpoint}?from_date=${from_date}&to_date=${to_date}`)
  }
  ConversationHistoryApi() {
    return AxiosAuth.get(this.jwtConfig.conversationApiEndpoint)
  }

  BusinessProfileApi() {
    return AxiosAuth.get(this.jwtConfig.businessProfileApiEndpoint)
  }

  BusinessProfileUpdateApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.businessProfileApiEndpoint}`, data)
  }
  BusinessEmbeddedSignupApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.businessEmbeddedSignupApiEndpoint}`, data)
  }

  getUserActivity(page = 1, page_size = 25, query = '', user_id = '') {
    return AxiosAuth.get(`${this.jwtConfig.userActivityApiEndpoint}?page=${page}&page_size=${page_size}&query=${query}&user_id=${user_id}`)
  }

  ExportContactApi(filter_type = 'All', filter_value = '') {
    return AxiosAuth.get(`${this.jwtConfig.contactImpExpApiEndpoint}?filter_type=${filter_type}&filter_value=${filter_value}`)
  }

  ImportContactApi(data) {
    return AxiosAuth.post(`${this.jwtConfig.contactImpExpApiEndpoint}`, data)
  }

  ///\payment api

  PricingApi(market = 'India') {
    return AxiosAuth.get(`${this.jwtConfig.pricingApiEndpoint}?market=${market}`)
  }

  SubscriptionApi() {
    return AxiosAuth.get(this.jwtConfig.subscriptionApiEndpoint)
  }

  // Stripe payment

  CreatePaymentCheckoutApi(data) {
    return AxiosAuth.post(this.jwtConfig.paymentCheckoutApiEndpoint, data)
  }

  UpdatePaymentCheckoutApi(data) {
    return AxiosAuth.put(this.jwtConfig.paymentCheckoutApiEndpoint, data)
  }

  CancelSubscriptionApi(data) {
    return AxiosAuth.post(this.jwtConfig.updateSubscriptionApiEndpoint, data)
  }

  UpgradeSubscriptionApi(data) {
    return AxiosAuth.put(this.jwtConfig.updateSubscriptionApiEndpoint, data)
  }

  ReactivateSubscriptionApi(data = {}) {
    return AxiosAuth.post(this.jwtConfig.reactivateSubscriptionApiEndpoint, data)
  }

  VerifyWabaSecretApi(data) {
    return AxiosAuth.put(this.jwtConfig.verifyWabaSecretApiEndpoint, data)
  }

  // Razorpay payment
  CreateRazorpayPaymentCheckoutApi(data) {
    return AxiosAuth.post(this.jwtConfig.razorpayPaymentCheckoutApiEndpoint, data)
  }

  GetRazorpayPaymentMethodApi() {
    return AxiosAuth.get(this.jwtConfig.razorpayPaymentCheckoutApiEndpoint)
  }

  CancelRazorpaySubscriptionApi(cancelled_at_end = true) {
    return AxiosAuth.delete(`${this.jwtConfig.razorpayPaymentCheckoutApiEndpoint}?cancel_at_cycle_end=${cancelled_at_end}`)
  }


}

