// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { ToastError, ToastSuccess } from '@components/toaster'

export const getBroadcastList = createAsyncThunk('broadcasts/getBroadcastList', async params => {
  if (params.q.length > 0) useJwt.handleCancelRequest()
  const response = await useJwt.BroadcastListApi(params.page, params.perPage, params.q, params.status, params.is_api_cast)
  if (response && response.data.success) {
    return {
      total: response.data.total,
      params,
      data: response.data.data
    }
  } else {
    return null
  }
})

export const getBroadcast = createAsyncThunk('broadcasts/getBroadcast', async id => {
  const response = await useJwt.BroadcastApi(id)
  if (response && response.data.success) {
    return response.data.data
  } else {
    return null
  }
})

export const createBroadcast = createAsyncThunk('broadcasts/createBroadcast', async (data) => {
  const response = await useJwt.BroadcastCreateApi(data)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response ? response.data.message : "Something went wrong.", "TR")
    return null
  }
})

export const updateBroadcast = createAsyncThunk('broadcasts/updateBroadcast', async (data) => {
  const response = await useJwt.BroadcastUpdateApi(data)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response ? response.data.message : "Something went wrong.", "TR")
    return null
  }
})

export const deleteBroadcast = createAsyncThunk('broadcasts/deleteBroadcast', async (id) => {
  const response = await useJwt.BroadcastDeleteApi(id)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response ? response.data.message : "Something went wrong.", "TR")
    return null
  }
})

export const broadcastsSlice = createSlice({
  name: 'broadcasts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: null,
    modalType: "create", //create | view | edit | Delete
    modalOpen: false,
    tableLoading: false,
    searchLoading: false
  },
  reducers: {
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen
      if (!state.modalOpen) {
        state.selected = null
      }
    },
    toggleModalType: (state, action) => { state.modalType = action.payload },
    setTableLoading: (state) => { state.tableLoading = true },
    setSearchLoading: (state) => { state.searchLoading = true },
    setSelected: (state, action) => { state.selected = action.payload }
  },
  extraReducers: builder => {
    builder.addCase(getBroadcastList.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = action.payload.data
        state.total = action.payload.total
        state.params = action.payload.params
        state.searchLoading = false
      }
      state.tableLoading = false
    }).addCase(getBroadcast.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = action.payload
      }
    }).addCase(createBroadcast.fulfilled, (state, action) => {
      if (action.payload) {
        state.total = state.total + 1
        state.data = [action.payload, ...state.data]
        state.modalOpen = false
      }
    }).addCase(updateBroadcast.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = state.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
        state.modalOpen = false
      }
    }).addCase(deleteBroadcast.fulfilled, (state, action) => {
      if (action.payload) {
        const data = state.data
        data.splice(data.findIndex(a => a.id === action.payload), 1)
        state.total = state.total - 1
        state.data = data
        state.modalOpen = false
      }
    })
  }
})

export const { selectContact, toggleModal, toggleModalType, setTableLoading, setSearchLoading, setSelected } = broadcastsSlice.actions

export default broadcastsSlice.reducer
