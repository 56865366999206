// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
// ** Axios Imports
import moment from 'moment'
import { PlaySound, IsConversationOpen, decodeUserData } from '@utils'
const DEFAULT_RECORD = 25

const findFileType = (obj) => {
  if (obj) {
    const extetion = obj.name.split('.').pop().toLocaleUpperCase()
    if (['JPEG', 'PNG', 'JPG', 'WEBP'].find((item) => item === extetion)) {
      return 'Image'
    } else if (['MP4', '3GPP'].find((item) => item === extetion)) {
      return 'Video'
    } else if (['MP3'].find((item) => item === extetion)) {
      return "Audio"
    } else {
      return 'Document'
    }
  }
}

const arrayMove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

const readchat = async (id) => {
  const response = await useJwt.ChatLogsReadApi(id)
  return response.data.success
}

const fetchchat = async (id) => {
  const response = await useJwt.ChatLogsApi(id, 1, DEFAULT_RECORD)
  if (response && response.data.success) {
    return response.data.data.chat.data.reverse()
  }
  return []
}

export const MsgLoading = createAsyncThunk('appChat/MsgLoading', (load) => {
  return !load
})

const SetWhoActive = createAsyncThunk('appChat/SetWhoActive', (load) => load)

export const ChatLoading = createAsyncThunk('appChat/ChatLoading', (load) => {
  return !load
})

export const updateTag = createAsyncThunk('appChat/updateTag', (obj) => {
  return obj
})

export const updateAssignedUser = createAsyncThunk('appChat/updateAssignedUser', (obj) => {
  return obj
})

export const handleTakeoverChat = createAsyncThunk('appChat/handleTakeoverChat', (obj) => {
  return obj
})
export const handleNewChat = createAsyncThunk('appChat/handleNewChat', (obj) => {
  return obj
})

export const getChatList = createAsyncThunk('appChat/getChatList', async (obj = null, { getState, dispatch }) => {
  try {
    const state_chats_data = getState().chat.chats.data
    if (obj !== null) {
      // check websocket event
      if (obj.event === "message_receive") {
        const { msg } = obj
        if (state_chats_data.some(item => item.last_msg.id === msg.last_msg.id)) {
          return null
        }
        const updatedChats = state_chats_data.filter(item => item.id !== msg.id)
        const userData = decodeUserData()
        // play sound when receive new message
        let isPlaySound = false
        if (msg.assigned_to === null) {
          isPlaySound = true
          if (window.location.pathname === '/chat' && getState().chat.Whos_Is_Active === msg.id) {
            updatedChats.unshift({ ...msg, new_count: 0 })
            readchat(msg.id)
          } else {
            updatedChats.unshift(msg)
          }
        } else if (msg?.assigned_to?.id === userData.cid) {
          isPlaySound = true
          if (window.location.pathname === '/chat' && getState().chat.Whos_Is_Active === msg.id) {
            updatedChats.unshift({ ...msg, new_count: 0 })
            readchat(msg.id)
          } else {
            updatedChats.unshift(msg)
          }
        } else {
          updatedChats.unshift({ ...msg, new_count: 0 })
        }
        if (isPlaySound && !msg.last_msg.is_note) {
          PlaySound()
        }
        const new_chatlist = getState().chat.chatList.find(item => item.contactId === msg.id)
        if (new_chatlist) {
          if (new_chatlist.chat.some(item => item.id === msg.last_msg.id)) {
            return null
          }
          if (new_chatlist.chat.length < 25 && new_chatlist.isMore) {
            const newchat = await fetchchat(msg.id)
            return { data: updatedChats, obj: { ...obj, msg: { ...obj.msg, last_msg: newchat } }, total: updatedChats.length }
          }
        }
        return { data: updatedChats, obj, total: updatedChats.length }
      } else if (obj.event === "Scroll") { // for scroll and load more data
        const state_chats = getState().chat.chats
        const response = await useJwt.ChatListApi(state_chats.curPage, DEFAULT_RECORD, obj.filter_type)
        if (response && response.data.success) {
          return { data: [...state_chats_data, ...response.data.data], obj, total: response.data.total }
        } else {
          return { data: state_chats_data, obj, total: state_chats_data.length }
        }
      }

    } else if (state_chats_data.length) {
      return { data: state_chats_data, obj, total: state_chats_data.length }
    } else {
      await dispatch(ChatLoading(false))
      const response = await useJwt.ChatListApi()
      if (response && response.data.success) {
        return { data: response.data.data, obj, total: response.data.total }
      } else {
        return { data: [], obj, total: 0 }
      }
    }
  } catch (e) {
    console.log("error", e)
    return null
  }
})

export const updateContactInfo = createAsyncThunk('appChat/updateContactInfo', (obj) => {
  return obj
})

export const handleChatBlock = createAsyncThunk('appChat/handleChatBlock', (obj) => {
  return obj
})

export const handleChatImportant = createAsyncThunk('appChat/handleChatImportant', (obj) => {
  return obj
})

export const selectChat = createAsyncThunk('appChat/selectChat', async (obj, { getState, dispatch }) => {
  try {
    await dispatch(SetWhoActive(obj.id))
    const state_chatlist = getState().chat.chatList
    const chatexist = state_chatlist.find(i => i.contactId === obj.id)
    if (chatexist) {
      if (obj.event === "click") {
        const refId = obj.refId || null
        if (refId) {
          if (chatexist.chat.length && chatexist.chat.some(ch => ch.id === refId)) {
            return { chatlist: state_chatlist, contactId: obj.id, event: obj.event, refId }
          } else {
            await dispatch(MsgLoading(false))
            let newSerachChat = chatexist
            let newChatlist = state_chatlist
            const conditionMatch = newSerachChat.chat.some(ch => ch.id === refId)
            while (!conditionMatch) {
              const response = await useJwt.ChatLogsApi(obj.id, newSerachChat.curPage, DEFAULT_RECORD)
              if (response && response.data.success) {
                const result = response.data.data.chat.data.reverse()
                newChatlist = newChatlist.map((item) => {
                  if (item.contactId === obj.id) {
                    const updatedItem = {
                      ...item,
                      chat: [...result, ...item.chat],
                      isMore: result.length === DEFAULT_RECORD,
                      curPage: result.length === DEFAULT_RECORD ? item.curPage + 1 : item.curPage
                    }
                    newSerachChat = updatedItem
                    return updatedItem
                  }
                  return item
                })

                if (result.length !== DEFAULT_RECORD) {
                  break
                }
              } else {
                break
              }
            }
            return { chatlist: newChatlist, contactId: obj.id, event: obj.event, refId }
          }
        } else if (chatexist.chat.length) {
          return { chatlist: state_chatlist, contactId: obj.id, event: obj.event }
        } else {
          await dispatch(MsgLoading(false))
          const response = await useJwt.ChatLogsApi(obj.id, chatexist.curPage, DEFAULT_RECORD)
          if (response && response.data.success) {
            const result = response.data.data.chat.data.reverse()
            const updateResult = state_chatlist.map((item) => {
              if (item.contactId === obj.id) {
                return {
                  ...item,
                  chat: [...result, ...item.chat],
                  isMore: result.length === DEFAULT_RECORD,
                  curPage: result.length === DEFAULT_RECORD ? item.curPage + 1 : item.curPage
                }
              }
              return item
            })
            return { chatlist: updateResult, contactId: obj.id, event: obj.event }
          } else {
            const updateResult = state_chatlist.map((item) => {
              if (item.contactId === obj.id) {
                return {
                  ...item,
                  isMore: false
                }
              }
              return item
            })
            return { chatlist: updateResult, contactId: obj.id, event: obj.event }
          }
        }
      } else if (chatexist.isMore) {
        const response = await useJwt.ChatLogsApi(obj.id, chatexist.curPage, DEFAULT_RECORD)
        if (response && response.data.success) {
          const result = response.data.data.chat.data.reverse()

          const updateResult = state_chatlist.map((item) => {
            if (item.contactId === obj.id) {
              const uniqueIds = []
              const uniqueChat = [...result, ...item.chat].filter(element => {
                const isDuplicate = uniqueIds.includes(element.id)
                if (!isDuplicate) {
                  uniqueIds.push(element.id)
                  return true
                }
                return false
              })
              return {
                ...item,
                chat: uniqueChat,
                //chat: [...result, ...item.chat],
                isMore: uniqueChat.length !== response.data.data.chat.total,
                curPage: uniqueChat.length !== response.data.data.chat.total ? item.curPage + 1 : item.curPage
              }
            }
            return item
          })

          return { chatlist: updateResult, contactId: obj.id, event: obj.event }
        } else {
          const updateResult = state_chatlist.map((item) => {
            if (item.contactId === obj.id) {
              return {
                ...item,
                isMore: false
              }
            }
            return item
          })
          return { chatlist: updateResult, contactId: obj.id, event: obj.event }
        }
      } else {
        return { chatlist: state_chatlist, contactId: obj.id, event: obj.event }
      }
    } else {
      await dispatch(MsgLoading(false))
      const response = await useJwt.ChatLogsApi(obj.id, 1, DEFAULT_RECORD)
      if (response && response.data.success) {
        const { chat, ...contactChat } = response.data.data
        const result = chat.data.reverse()
        const { total } = chat
        const updateResult = {
          contactId: obj.id,
          chat: result,
          isMore: total > DEFAULT_RECORD,
          isLoading: false,
          curPage: total > DEFAULT_RECORD ? 2 : 1
        }
        let refId = null
        if (obj.event === "click") {
          refId = obj.refId || null
        }
        return { chatlist: [...state_chatlist, updateResult], contactId: obj.id, event: obj.event, user: contactChat, filter_chat: true, refId }
      } else {
        return null
      }
    }
  } catch (e) {
    console.log("error", e)
    return null
  }
})

export const addMsg = createAsyncThunk('appChat/addMsg', async (obj) => {
  return { data: obj, event: "sending" }
})

export const ChangeChatType = createAsyncThunk('appChat/changeChatType', async (chat_type, { dispatch }) => {
  await dispatch(ChatLoading(false))
  const response = await useJwt.ChatListApi(1, DEFAULT_RECORD, chat_type)
  if (response && response.data.success) {
    return { data: response.data.data, chat_type, total: response.data.total }
  } else {
    return { data: [], chat_type, total: 0 }
  }
})

export const sendMsg = createAsyncThunk('appChat/sendMsg', async (obj, { dispatch }) => {
  let local_msg = {
    id: obj.get('local_id'),
    contact: obj.get('contact_id'),
    initiate_by_business: true,
    created_at: moment.utc().format(),
    chat_type: obj.get('message_type'),
    messageid: null,
    media: null,
    is_note: obj.get('is_note') === 'true',
    reply_source: null,
    status: "Sending"
  }
  if (obj.get('message_type') === "Text") {
    local_msg = { ...local_msg, message: { text: obj.get('message') } }

  } else if (obj.get('message_type') === "Template") {
    const message = JSON.parse(obj.get('template_message'))
    if (message.header.Type === "Media") {
      local_msg = { ...local_msg, message, media: { url: message.header.Media_url } }
    } else {
      local_msg = { ...local_msg, message }
    }
    obj.delete('template_message')

  } else if (obj.get('message_type') === "Media") {
    const chat_type = findFileType(obj.get('media'))
    local_msg = { ...local_msg, chat_type, message: { text: chat_type, caption: obj.get('caption') }, media: { url: obj.get('media_url'), file_name: obj.get('file_name') } }
    obj.delete('media_url')
    obj.delete('file_name')

  } else if (obj.get('message_type') === "QuickResponse") {
    const chat_type = obj.get('msg_type')
    if (chat_type === 'Text') {
      local_msg = { ...local_msg, chat_type, message: { text: obj.get('caption') } }
    } else {
      local_msg = { ...local_msg, chat_type, message: { text: obj.get('caption'), caption: obj.get('caption') }, media: { url: obj.get('media_url'), file_name: obj.get('file_name') } }
      obj.delete('media_url')
      obj.delete('file_name')
    }
    obj.delete('caption')

  }
  await dispatch(addMsg(local_msg))
  const response = await useJwt.ChatLogsCreateApi(obj)
  if (response && response.data.success) {
    return { data: response.data.data, event: "sent", local_id: response.data.local_id }
  } else {
    return []
  }
})

export const updateMsg = createAsyncThunk('appChat/updateMsg', async (obj) => {
  return { data: obj, event: "updating" }
})

export const resolveChat = createAsyncThunk('appChat/resolveChat', (obj) => {
  return obj
})

export const FilterChatMesaage = createAsyncThunk('appChat/filterChatList', async (obj, { getState }) => {
  try {
    const filter_chat_list = getState().chat.filterChatList
    const { selectedChatType } = getState().chat
    if (obj.event === "Scroll") {
      const page_no = Math.max(filter_chat_list.chats.curPage, filter_chat_list.messages.curPage)
      const response = await useJwt.SearchChatApi(page_no, DEFAULT_RECORD, selectedChatType, obj.query, filter_chat_list.chats.isMore, filter_chat_list.messages.isMore)
      if (response && response.data.success) {
        const result = response.data.data

        const chat_local = result.contact.data.length + filter_chat_list.chats.data.length
        const msg_local = result.message.data.length + filter_chat_list.messages.data.length

        const ischat_more = chat_local > DEFAULT_RECORD && chat_local !== result.contact.total
        const ismsg_more = msg_local > DEFAULT_RECORD && msg_local !== result.message.total
        return {
          chats: {
            data: [...filter_chat_list.chats.data, ...result.contact.data],
            isMore: ischat_more,
            curPage: ischat_more ? filter_chat_list.chats.curPage + 1 : filter_chat_list.chats.curPage
          },
          messages: {
            data: [...filter_chat_list.messages.data, ...result.message.data],
            isMore: ismsg_more,
            curPage: ismsg_more ? filter_chat_list.messages.curPage + 1 : filter_chat_list.messages.curPage
          }
        }
      } else {
        return filter_chat_list
      }
    } else {
      if (obj.query.length > 0) {
        useJwt.handleCancelRequest()
      }
      const response = await useJwt.SearchChatApi(1, DEFAULT_RECORD, selectedChatType, obj.query)
      if (response && response.data.success) {
        const result = response.data.data
        return {
          chats: {
            data: result.contact.data,
            isMore: result.contact.data.length === DEFAULT_RECORD,
            curPage: result.contact.data.length === DEFAULT_RECORD ? 2 : 1
          },
          messages: {
            data: result.message.data,
            isMore: result.message.data.length === DEFAULT_RECORD,
            curPage: result.message.data.length === DEFAULT_RECORD ? 2 : 1
          }
        }
      } else {
        return null
      }
    }
  } catch (e) {
    console.log("error", e)
  }
})

export const FilterContactMesaage = createAsyncThunk('appChat/filterContactList', async (obj, { getState }) => {
  try {
    const filter_message_list = getState().chat.filterContactMessages
    if (obj.event === "Scroll") {
      const response = await useJwt.SearchContactMessageApi(filter_message_list.curPage, DEFAULT_RECORD, obj.id, obj.query)
      const Messageresult = response.data.data
      if (response && response.data.success) {
        return {
          data: [...filter_message_list.data, ...Messageresult],
          isMore: Messageresult.length === DEFAULT_RECORD,
          curPage: Messageresult.length === DEFAULT_RECORD ? filter_message_list.curPage + 1 : filter_message_list.curPage
        }
      } else {
        return filter_message_list
      }

    } else {
      if (obj.query.length > 0) {
        useJwt.handleCancelRequest()
      }
      const response = await useJwt.SearchContactMessageApi(1, DEFAULT_RECORD, obj.id, obj.query)
      const Messageresult = response.data.data
      if (response && response.data.success) {
        return {
          data: Messageresult,
          isMore: Messageresult.length === DEFAULT_RECORD,
          curPage: Messageresult.length === DEFAULT_RECORD ? 2 : 1
        }
      } else {
        return null
      }
    }
  } catch (e) {
    console.log("error", e)
  }
})

export const EmptyFilterContactMessage = createAsyncThunk('appChat/emptyFilterContactMessage', async () => {
  return {
    data: [],
    isMore: true,
    curPage: 1
  }
})

export const EmptyFilterChatList = createAsyncThunk('appChat/emptyFilterChatList', async () => {
  return {
    chats: {
      data: [],
      isMore: true,
      curPage: 1
    },
    messages: {
      data: [],
      isMore: true,
      curPage: 1
    }
  }
})


export const appChatSlice = createSlice({
  name: 'appChat',
  initialState: {
    chats: {
      data: [],
      isMore: true,
      isLoading: false,
      curPage: 1
    }, // all user contact and last message 
    chatList: [], //all user chats list
    filterChatList: {
      chats: {
        data: [],
        isMore: true,
        curPage: 1
      },
      messages: {
        data: [],
        isMore: true,
        curPage: 1
      }
    }, // seach chats or messages
    tempChats: [], // temperary store the contactinfo when filter
    filterContactMessages: {
      data: [],
      isMore: true,
      curPage: 1
    }, //filter selected contact messages
    filterChatLoading: false, // after fetch data set false when search chat
    filterContactChatLoading: false, // after fetch data set false when search chat
    selectedUser: {}, //selected user data and chat
    medialList: [], // upload media list choose from local PC to preview, set list of media 
    Whos_Is_Active: null, // curent selected user id, set id 
    Switch_Active: false, // switch between users , set true
    Receive_from_socket: false, // when receive message from websocket, set true
    userchatlen: { prev: 0, next: 0 }, // selected user chatlist lenght both before and after when scroll to load more chat
    chatsLoading: false, //first time load user list
    messageLoading: false, //first time load selected user chats list
    isNote: false, //check the message send weather the message is note or reply
    isChatSocketConncted: false,
    selectedChatType: "All"//selected chat Type like All chat , Broadcast Chat ExpiredChat,
  },
  reducers: {
    MediaPreviewList: (state, action) => {
      if (action.payload.length === 0) {
        state.medialList = []
      } else {
        state.medialList = action.payload
      }
    },
    SetIsNote: (state, action) => {
      state.isNote = action.payload
    },
    handleChatSocketConncted: (state, action) => {
      state.isChatSocketConncted = action.payload
    },
    setFilterChatLoading: (state, action) => {
      state.filterChatLoading = action.payload
    },
    setFilterContactChatLoading: (state, action) => {
      state.filterContactChatLoading = action.payload
    },
    setChatEmptyLogout: (state) => {
      state.chats = { data: [], isMore: true, isLoading: false, curPage: 1 }
      state.selectedUser = {}
    }


  },
  extraReducers: builder => {
    builder
      .addCase(getChatList.fulfilled, (state, action) => {
        if (action.payload === null) {
          return
        }
        const { total, obj } = action.payload
        const userData = decodeUserData()
        const data = action.payload.data.map((user) => {
          if (user.conversation_expire && !IsConversationOpen(user.conversation_expire)) {
            const status = user.status !== "Broadcast" ? "Expired" : user.status
            const assigned_to = userData.is_auto_assigned_remove ? null : user.assigned_to
            const takeover_request = userData.is_auto_assigned_remove ? null : user.takeover_request
            const takeover_from = userData.is_auto_assigned_remove ? null : user.takeover_from
            const takeover_to = userData.is_auto_assigned_remove ? null : user.takeover_to
            return { ...user, assigned_to, takeover_request, takeover_from, takeover_to, status }
          }
          return user
        })
        const curPage = total !== data.length ? state.chats.curPage + 1 : state.chats.curPage
        state.chats = { isMore: total !== data.length, curPage, isLoading: false, data }
        if (obj === null) {
          if (state.chatList.length === 0) {
            state.chatList = data?.map((item) => {
              return { contactId: item.id, chat: [], isMore: true, isLoading: false, curPage: 1 }
            })
          }
          state.chatsLoading = false
        } else if (obj.event === "message_receive") {
          const { msg } = obj
          state.Receive_from_socket = true
          const new_chatlist = state.chatList.find(item => item.contactId === msg.id)
          if (new_chatlist) {
            const index_chatlist = state.chatList.indexOf(new_chatlist)
            if (Array.isArray(msg.last_msg)) {
              state.chatList[index_chatlist].chat = [...state.chatList[index_chatlist].chat, ...msg.last_msg]
            } else if (state.chatList[index_chatlist].chat.length > 0) {
              state.chatList[index_chatlist].chat = [...state.chatList[index_chatlist].chat, msg.last_msg]
            }
            if (state.Whos_Is_Active === msg.id) {
              if (Array.isArray(msg.last_msg)) {
                state.selectedUser = { ...state.selectedUser, ...msg, chat: [...state.selectedUser.chat, ...msg.last_msg] }
              } else {
                state.selectedUser = { ...state.selectedUser, ...msg, chat: [...state.selectedUser.chat, msg.last_msg] }
              }
            }
          } else {
            state.chatList = [...state.chatList, { contactId: msg.id, chat: [], isMore: true, isLoading: false, curPage: 1 }]
          }
        } else if (obj.event === "Scroll") {
          state.chatList = data?.map((item) => {
            return { contactId: item.id, chat: [], isMore: true, isLoading: false, curPage: 1 }
          })
        }
      })
      .addCase(handleNewChat.fulfilled, (state, action) => {
        const msg = action.payload
        state.Receive_from_socket = true
        state.chats = { ...state.chats, data: [msg, ...state.chats.data] }
        state.chatList = [...state.chatList, { contactId: msg.id, chat: [{ ...msg.last_msg }], isMore: false, isLoading: false, curPage: 1 }]
        state.selectedUser = { ...msg, chat: [msg.last_msg] }
        state.Whos_Is_Active = msg.id
      })
      .addCase(updateContactInfo.fulfilled, (state, action) => {
        if (action.payload === null) {
          return
        }
        const { event, data } = action.payload
        const contactId = data.contact_id
        const contactExist = state.chats.data.find(item => item.id === contactId)
        if (contactExist) {
          const index_contact = state.chats.data.indexOf(contactExist)
          if (["chat_assignment", "chat_takeover"].includes(event)) {
            const { assigned_to, takeover_request, takeover_from, takeover_to } = data
            const updatedInfo = { ...contactExist, assigned_to, takeover_request, takeover_from, takeover_to }
            state.chats.data[index_contact] = updatedInfo
            if (state.Whos_Is_Active === contactId) {
              state.selectedUser = { ...state.selectedUser, assigned_to, takeover_request, takeover_from, takeover_to }
            }
          } else if (event === "chat_tag_add") {
            const updatedInfo = { ...contactExist, tag: [...contactExist.tag, data.tag] }
            state.chats.data[index_contact] = updatedInfo
            if (state.Whos_Is_Active === contactId) {
              state.selectedUser = { ...state.selectedUser, tag: [...contactExist.tag, data.tag] }
            }
          } else if (event === "chat_tag_remove") {
            const updatedInfo = { ...contactExist, tag: contactExist.tag.filter(item => item !== data.tag) }
            state.chats.data[index_contact] = updatedInfo
            if (state.Whos_Is_Active === contactId) {
              state.selectedUser = { ...state.selectedUser, tag: contactExist.tag.filter(item => item !== data.tag) }
            }
          } else if (event === "chat_important") {
            const updatedInfo = { ...contactExist, tag: contactExist.tag.filter(item => item !== data.tag) }
            state.chats.data[index_contact] = updatedInfo
            if (state.Whos_Is_Active === contactId) {
              state.selectedUser = { ...state.selectedUser, is_favorite: data.is_favorite }
            }
          } else if (event === "chat_block") {
            const updatedInfo = { ...contactExist, tag: contactExist.tag.filter(item => item !== data.tag) }
            state.chats.data[index_contact] = updatedInfo
            if (state.Whos_Is_Active === contactId) {
              state.selectedUser = { ...state.selectedUser, is_spam: data.is_spam }
            }
          } else if (event === "chat_delete") {
            const updatedInfo = state.chats.data
            updatedInfo.splice(index_contact, 1)
            state.chats.data = updatedInfo
            state.chatList = state.chatList.filter(item => item.contactId !== contactId)
            if (state.Whos_Is_Active === contactId) {
              state.selectedUser = {}
              state.Whos_Is_Active = null
            }
          }

        }
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.payload.event === "click") {
            state.Switch_Active = true
          } else {
            state.Switch_Active = false
          }

          if (action.payload?.filter_chat) { // when user search contact, contact not exists in local data
            const { user } = action.payload
            state.tempChats = [user, ...state.tempChats]
            const chat = action.payload.chatlist.find(i => i.contactId === action.payload.contactId)
            const refId = action.payload.refId || null
            state.selectedUser = { ...user, ...chat, refId }
            state.Receive_from_socket = false
            state.chatList = action.payload.chatlist
            if (action.payload.contactId === state.Whos_Is_Active) {
              state.userchatlen = { prev: state.userchatlen.next, next: chat.chat.length }
            } else {
              state.userchatlen = { prev: 0, next: chat.chat.length }
            }
            if (state.Whos_Is_Active !== user.id) {
              state.Whos_Is_Active = user.id
            }
            state.messageLoading = false
          } else {
            let user = state.chats.data.find(item => item.id === action.payload.contactId)
            if (!user) {
              user = state.tempChats.find(item => item.id === action.payload.contactId)
            }
            const chat = action.payload.chatlist.find(i => i.contactId === action.payload.contactId)
            const refId = action.payload.refId || null
            state.Receive_from_socket = false
            state.chatList = action.payload.chatlist
            if (action.payload.contactId === state.Whos_Is_Active) {
              state.userchatlen = { prev: state.userchatlen.next, next: chat.chat.length }
            } else {
              state.userchatlen = { prev: 0, next: chat.chat.length }
            }
            if (user) {
              if (state.Whos_Is_Active !== user.id) {
                state.Whos_Is_Active = user.id
              }
              if (user.new_count > 0) {
                const updated_chat_list = [...state.chats.data]
                updated_chat_list[updated_chat_list.findIndex(i => i.id === action.payload.contactId)].new_count = 0
                state.chats = { ...state.chats, data: updated_chat_list }
                readchat(action.payload.contactId)
              }
              state.selectedUser = { ...user, ...chat, refId }
            }
            state.messageLoading = false
          }
        }
      })
      .addCase(updateTag.fulfilled, (state, action) => {
        if (action.payload.event === "remove") {
          const updData = state.chats.data.map(item => {
            if (item.id === action.payload.contactId) {
              return {
                ...item, tag: item.tag.filter(obj => obj !== action.payload.name)
              }
            }
            return item
          })
          state.chats = { ...state.chats, data: updData }
          state.selectedUser = { ...state.selectedUser, tag: state.selectedUser.tag.filter(obj => obj !== action.payload.name) }
        } else {
          const updData = state.chats.data.map(item => {
            if (item.id === action.payload.contactId) {
              return { ...item, tag: [action.payload.name, ...item.tag] }
            }
            return item
          })
          state.chats = { ...state.chats, data: updData }
          state.selectedUser = { ...state.selectedUser, tag: [action.payload.name, ...state.selectedUser.tag] }
        }
      })
      .addCase(updateAssignedUser.fulfilled, (state, action) => {
        const { contactId } = action.payload
        const updData = state.chats.data.map(item => {
          if (item.id === contactId) {
            return {
              ...item, assigned_to: action.payload.assigned_to, takeover_request: null, takeover_from: null, takeover_to: null
            }
          }
          return item
        })
        state.chats = { ...state.chats, data: updData }
        state.selectedUser = { ...state.selectedUser, assigned_to: action.payload.assigned_to, takeover_request: null, takeover_from: null, takeover_to: null }
      })
      .addCase(handleTakeoverChat.fulfilled, (state, action) => {
        const contactId = action.payload.contact_id
        const { takeover_request, takeover_from, takeover_to, assigned_to } = action.payload
        const updData = state.chats.data.map(item => {
          if (item.id === contactId) {
            return { ...item, assigned_to, takeover_request, takeover_from, takeover_to }
          }
          return item
        })
        state.chats = { ...state.chats, data: updData }
        state.selectedUser = { ...state.selectedUser, assigned_to, takeover_request, takeover_from, takeover_to }
      })
      .addCase(handleChatBlock.fulfilled, (state, action) => {
        const contactId = action.payload.contact_id
        const updData = state.chats.data.filter(item => item.id !== contactId)
        state.chats = { ...state.chats, data: updData }
        state.selectedUser = {}
        state.Whos_Is_Active = null
      })
      .addCase(handleChatImportant.fulfilled, (state, action) => {
        const contactId = action.payload.contact_id
        const { is_favorite } = action.payload
        const updData = state.chats.data.map(item => {
          if (item.id === contactId) {
            return { ...item, is_favorite }
          }
          return item
        })
        state.chats = { ...state.chats, data: updData }
        state.selectedUser = { ...state.selectedUser, is_favorite }
      })
      .addCase(addMsg.fulfilled, (state, action) => {
        if (action.payload.event === "sending") {
          state.Receive_from_socket = true
          const msg = action.payload.data
          const new_chat = state.chats.data.find(item => item.id === msg.contact)
          if (new_chat) {
            const index_chat = state.chats.data.indexOf(new_chat)
            const newchatdata = { ...new_chat, last_msg: msg }
            state.chats.data[index_chat] = newchatdata
            state.chats = { ...state.chats, isLoading: false, data: arrayMove(state.chats.data, index_chat, 0) }
          }
          const new_chatlist = state.chatList.find(item => item.contactId === msg.contact)
          if (new_chatlist) {
            const index_chatlist = state.chatList.indexOf(new_chatlist)
            state.chatList[index_chatlist].chat = [...state.chatList[index_chatlist].chat, msg]
            state.selectedUser = { ...state.selectedUser, chat: [...state.selectedUser.chat, msg] }
          }
        }
      })
      .addCase(sendMsg.fulfilled, (state, action) => {
        state.Receive_from_socket = true
        if (action.payload.event === "sent") {
          const msg = action.payload.data
          const { local_id } = action.payload

          const new_chat = state.chats.data.find(item => item.id === msg.contact)
          if (new_chat) {
            const index_chat = state.chats.data.indexOf(new_chat)
            const newchatdata = { ...new_chat, last_msg: msg }
            state.chats.data[index_chat] = newchatdata
            state.chats = { ...state.chats, isLoading: false, data: arrayMove(state.chats.data, index_chat, 0) }
          }

          const new_chatlist = state.chatList.find(item => item.contactId === msg.contact)
          if (new_chatlist) {
            const index_chatlist = state.chatList.indexOf(new_chatlist)
            const newchatls = state.chatList[index_chatlist].chat.map(item => {
              if (item.id === local_id) {
                return msg
              }
              return item
            })
            state.chatList[index_chatlist].chat = newchatls
            const newseluserchat = state.selectedUser.chat.map(item => {
              if (item.id === local_id) {
                return msg
              }
              return item
            })
            state.selectedUser = { ...state.selectedUser, chat: newseluserchat }
          }
        }
      })
      .addCase(updateMsg.fulfilled, (state, action) => {
        state.Receive_from_socket = true
        if (action.payload && action.payload.event === "updating") {
          const msg = action.payload.data

          let is_msg_update = false
          const new_chatlist = state.chatList.find(item => item.contactId === msg.id)
          if (new_chatlist) {
            const index_chatlist = state.chatList.indexOf(new_chatlist)
            let newchatls = state.chatList[index_chatlist].chat.map(item => {
              if (item.id === msg.last_msg.id) {
                is_msg_update = true
                return msg.last_msg
              }
              return item
            })
            // receive msg where msg come from api 
            if (!is_msg_update && newchatls.length > 0) {
              newchatls = [...newchatls, msg.last_msg]
            }
            state.chatList[index_chatlist].chat = newchatls
          } else {
            state.chatList = [...state.chatList, { contactId: msg.id, chat: [], isMore: true, isLoading: false, curPage: 1 }]
          }

          const new_chat = state.chats.data.find(item => item.id === msg.id)
          const lastChatContact = state.chats.data[state.chats.data.length - 1]
          if (new_chat) {
            const index_chat = state.chats.data.indexOf(new_chat)
            state.chats.data[index_chat] = msg
            if (!is_msg_update) {
              state.chats = { ...state.chats, data: arrayMove(state.chats.data, index_chat, 0) }
            }
          } else if (new Date(msg.recent_chattime) > new Date(lastChatContact.recent_chattime)) {
              const newchatData = state.chats.data
              newchatData.unshift(msg)
              state.chats = { ...state.chats, data: newchatData }
          }

          if (state.selectedUser.id === msg.id) {
            const newseluserchat = state.selectedUser.chat.find(item => item.id === msg.last_msg.id)
            if (newseluserchat) {
              const index_selecteduserchat = state.selectedUser.chat.indexOf(newseluserchat)
              state.selectedUser.chat[index_selecteduserchat] = msg.last_msg
            } else {
              state.selectedUser.chat = [...state.selectedUser.chat, msg.last_msg]
            }
          }

        }
      })
      .addCase(resolveChat.fulfilled, (state, action) => {
        if (action.payload) {
          const new_chat = state.chats.data.find(item => item.id === action.payload.contact_id)
          if (new_chat) {
            const index_chat = state.chats.data.indexOf(new_chat)
            if (action.payload.status_type === 'Reopen') {
              state.chats.data[index_chat] = { ...state.chats.data[index_chat], status: "Open" }
              state.selectedUser = { ...state.selectedUser, status: "Open" }
            } else {
              state.chats.data[index_chat] = { ...state.chats.data[index_chat], status: "Resolved" }
              state.selectedUser = { ...state.selectedUser, status: "Resolved" }
            }
          }
        }
      })
      .addCase(MsgLoading.fulfilled, (state, action) => {
        state.messageLoading = action.payload
      })
      .addCase(ChatLoading.fulfilled, (state, action) => {
        state.chatsLoading = action.payload
      })
      .addCase(FilterChatMesaage.fulfilled, (state, action) => {
        if (action.payload) {
          state.filterChatList = action.payload
          state.filterChatLoading = false
        }
      })
      .addCase(EmptyFilterChatList.fulfilled, (state, action) => {
        state.filterChatList = action.payload
        state.filterChatLoading = false
        state.tempChats = []
      })
      .addCase(FilterContactMesaage.fulfilled, (state, action) => {
        if (action.payload) {
          state.filterContactMessages = action.payload
          state.filterContactChatLoading = false
        }
      })
      .addCase(EmptyFilterContactMessage.fulfilled, (state, action) => {
        state.filterContactMessages = action.payload
        state.filterContactChatLoading = false
      })
      .addCase(SetWhoActive.fulfilled, (state, action) => {
        state.Whos_Is_Active = action.payload
        state.searchLoading = false
      })
      .addCase(ChangeChatType.fulfilled, (state, action) => {
        const { total, data } = action.payload
        const curPage = total !== data.length ? 2 : 1
        state.selectedChatType = action.payload.chat_type
        state.chats = { isMore: total !== data.length, curPage, isLoading: false, data: [...data] }
        state.chatList = data?.map((item) => {
          return { contactId: item.id, chat: [], isMore: true, isLoading: false, curPage: 1 }
        })
        state.chatsLoading = false
      })
  }
})

export default appChatSlice.reducer
export const {
  MediaPreviewList,
  SetIsNote,
  handleChatSocketConncted,
  setFilterChatLoading,
  setFilterContactChatLoading,
  setChatEmptyLogout

} = appChatSlice.actions
