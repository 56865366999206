// ** Auth Endpoints
export default {
  loginEndpoint: 'api/user/login',
  registerEndpoint: 'api/user/register',
  refreshEndpoint: 'api/user/refreshtoken',
  forgotPasswordOtpEndpoint: '/api/user/forgot_password_otp',
  resetPasswordEndpoint: '/api/user/reset_password',
  registerOtpEndpoint: '/api/user/register_otp',
  logoutEndpoint: 'api/user/logout',
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: '__tnac',
  storageRefreshTokenKeyName: '__rctnper',

  authCheckApiEndpoint: 'api/user/auth_check',
  chatListApiEndpoint: 'api/chatlist',
  chatLogsApiEndpoint: 'api/chat/logs/',
  chatLogsReadApiEndpoint: 'api/chat/read/',
  chatLogsResolveApiEndpoint: 'api/chat/resolve',
  chatImportantApiEndpoint: 'api/chat/important',
  chatBlockApiEndpoint: 'api/chat/block',
  serachContactMessageApiEndpoint: 'api/chat/search/contact/message/',
  serachChatApiEndpoint: 'api/chat/search/chat/',
  chatLogsCreateApiEndpoint: 'api/chat/create/logs',
  newChatApiEndpoint: 'api/chat/new/message',
  TemplateApiEndpoint: "api/template",
  contactApiEndpoint: "api/contact",
  broadcastApiEndpoint: "api/broadcast",
  broadcastContactSearchApiEndpoint: "api/broadcast_contact_search",
  broadcastDefaultContactTagApiEndpoint: "api/broadcast_default_contact_tag",
  newuserApiEndpoint: "api/user/users",
  assignchatApiEndpoint: "api/assignchat",
  takeoverchatApiEndpoint: "api/takeoverchat",
  tagApiEndpoint: "api/tag",
  notificationApiEndpoint: "api/notification",
  quickresponseApiEndpoint: "api/quickresponse",
  webhookApiEndpoint: "api/icwebhook",
  webhookEventApiEndpoint: "api/icwebhookevent",
  apikeyApiEndpoint: "api/apikey",
  dashboardApiEndpoint: "api/user/dashboard",
  userProfileApiEndpoint: "api/user/profile",
  conversationApiEndpoint: "api/user/conversation/history",
  businessProfileApiEndpoint: "api/user/business_profile",
  businessEmbeddedSignupApiEndpoint: "api/user/business/embedded_signup",
  ///// $$$$ analytics
  messageAnalyticsApiEndpoint: "api/user/analytics/message/sent_received",
  businessSettingsApiEndpoint: "api/business/settings",
  userActivityApiEndpoint: "api/user/activity",
  contactImpExpApiEndpoint: "api/contact_impexp",
  // payment
  pricingApiEndpoint: 'api/user/plan',
  subscriptionApiEndpoint: "api/user/subscription",
  paymentCheckoutApiEndpoint: "api/payment/checkout",
  updateSubscriptionApiEndpoint: "api/payment/update_subscription",
  reactivateSubscriptionApiEndpoint: "api/payment/reactivate_subscription",
  verifyWabaSecretApiEndpoint: "api/user/verify_waba_secret",
  // razorpay payemnt
  razorpayPaymentCheckoutApiEndpoint:"api/payment/razorpay/checkout"


}
