// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { ToastError, ToastSuccess } from '@components/toaster'

export const getContact = createAsyncThunk('contacts/getContact', async (params) => {
  try {
    if (params.q.length > 0) {
      useJwt.handleCancelRequest()
    }
    const response = await useJwt.ContactListApi(params.page, params.perPage, params.q)
    if (response && response.data.success) {
      return {
        total: response.data.total,
        params,
        allData: response.data.data,
        data: response.data.data
      }
    }
    return null
  } catch (e) { console.log("Error --> ", e) }

})

export const addContact = createAsyncThunk('contacts/addContact', async (data) => {
  const response = await useJwt.ContactCreateApi(data)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  }
  ToastError(null, response.data.message, "TR")
  return null
}
)

export const updateContact = createAsyncThunk('contacts/updateContact', async (data) => {
  const response = await useJwt.ContactUpdateApi(data)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  }
  ToastError(null, response.data.message, "TR")
  return null
}
)

export const deleteContact = createAsyncThunk('contacts/deleteContact', async (id) => {
  const response = await useJwt.ContactDeleteApi(id)
  if (response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return id
  }
  ToastError(null, response.data.message, "TR")
  return null
})


export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    data: [],
    total: 1,
    params: {},
    tableLoading: false,
    searchLoading: false,
    submitLoading: false,
    modalOpen: false,
    modalType: "Create",
    selected: null
  },
  reducers: {
    setSelected: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setTableLoading: (state) => {
      state.tableLoading = true
    },
    setSubmitLoading: (state) => {
      state.submitLoading = true
    },
    setSearchLoading: (state) => {
      state.searchLoading = true
    },
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen
    },
    setModalType: (state, action) => {
      state.modalType = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getContact.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = action.payload.data
        state.total = action.payload.total
        state.params = action.payload.params
        state.searchLoading = false
      }
      state.tableLoading = false
    }).addCase(addContact.fulfilled, (state, action) => {
      if (action.payload) {
        state.total += 1
        state.data = [action.payload, ...state.data]
        state.modalOpen = !state.modalOpen
      }
      state.submitLoading = false
    }).addCase(updateContact.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = state.data.map(item => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
        state.modalOpen = !state.modalOpen
      }
      state.submitLoading = false
    }).addCase(deleteContact.fulfilled, (state, action) => {
      if (action.payload) {
        state.total -= 1
        state.data = state.data.filter(item => item.id !== action.payload)
        state.modalOpen = !state.modalOpen
      }
      state.submitLoading = false
    })
  }
})

export const { setSelected, setTableLoading, setSubmitLoading, setSearchLoading, toggleModal, setModalType } = contactsSlice.actions

export default contactsSlice.reducer
