// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ToastError, ToastSuccess } from '@components/toaster'
import useJwt from '@src/auth/jwt/useJwt'

export const getData = createAsyncThunk('appWebhook/getData', async params => {
  if (params.q.length > 0) useJwt.handleCancelRequest()
  const response = await useJwt.GetWebhookApi(params.page, params.perPage, params.q)
  if (response && response.data.success) {
    return {
      total: response.data.total,
      params,
      data: response.data.data
    }
  }
  return null
})

export const addWebhook = createAsyncThunk('appWebhook/addwebhook', async (obj, { dispatch, getState }) => {
  const response = await useJwt.CreateWebhookApi(obj)
  if (response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    await dispatch(getData(getState().webhooks.params))
  } else {
    ToastError(null, response.data.message, "TR")
  }
  return user

})

export const updateWebhook = createAsyncThunk('appWebhook/updatewebhook', async (obj, { dispatch, getState }) => {
  const response = await useJwt.UpdateWebhookApi(obj)
  if (response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    await dispatch(getData(getState().webhooks.params))
  } else {
    ToastError(null, response.data.message, "TR")
  }
  return user

})

export const deleteWebhook = createAsyncThunk('appWebhook/deletewebhook', async (tag_id, { dispatch, getState }) => {
  const response = await useJwt.DeleteWebhookApi(tag_id)
  if (response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    await dispatch(getData(getState().webhooks.params))
  } else {
    ToastError(null, response.data.message, "TR")
  }
  return tag_id
})

export const appWebhookSlice = createSlice({
  name: 'appWebhook',
  initialState: {
    data: [],
    total: 1,
    params: {},
    tableLoading: false
  },
  reducers: {
    setTableLoading: (state) => {
      state.tableLoading = true
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
        }
        state.tableLoading = false
      })
  }
})

export const { setTableLoading } = appWebhookSlice.actions
export default appWebhookSlice.reducer
