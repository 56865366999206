import { Fragment } from 'react'
import { toast } from 'react-toastify'
import Avatar from '@components/avatar'
import { AlertTriangle, Check } from 'react-feather'

const ToastErrorComponent = ({ title, message }) => (
  <Fragment>
    {title &&
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='danger' icon={<AlertTriangle size={12} />} />
          <h6 className='toast-title'>{title}</h6>
        </div>
      </div>
    }
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {message}
      </span>
    </div>
  </Fragment>
)

const ToastSuccessComponent = ({ title, message }) => (
  <Fragment>
    {title &&
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='success' icon={<Check size={12} />} />
          <h6 className='toast-title'>{title}</h6>
        </div>
      </div>
    }
    <div className='toastify-body'>
      <span role='img' aria-label='toast-text'>
        {message}
      </span>
    </div>
  </Fragment>
)
const getPosition = (position) => {
  if (position === 'TL') {
    return toast.POSITION.TOP_LEFT
  } else if (position === 'TC') {
    return toast.POSITION.TOP_CENTER
  } else if (position === 'TR') {
    return toast.POSITION.TOP_RIGHT
  } else if (position === 'BL') {
    return toast.POSITION.BOTTOM_LEFT
  } else if (position === 'BC') {
    return toast.POSITION.BOTTOM_CENTER
  } else {
    return toast.POSITION.BOTTOM_RIGHT
  }
}
export const ToastError = (title = null, message, position = "TR", hideBar = false) => {
  toast.error(<ToastErrorComponent title={title} message={message} />, {
    icon: false, hideProgressBar: hideBar, position: getPosition(position)
  }
  )
}
export const ToastSuccess = (title = null, message, position = "TR", hideBar = false) => {
  toast.success(<ToastSuccessComponent title={title} message={message} />, {
    icon: false, hideProgressBar: hideBar, position: getPosition(position)
  })
}