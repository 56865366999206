// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

// const initialUser = () => {
//   const item = window.localStorage.getItem("userData")
//   //** Parse stored json or if none return initialValue
//   return item ? JSON.parse(item) : {}
// }

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: { isLoading: true },
    notifications: [],
    isSocketConnect: false
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload.userData
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      state.isSocketConnect = false
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      state.notifications = []
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('__enud')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload }
    },
    handleSocketConnect: (state, action) => {
      state.isSocketConnect = action.payload
    },
    hanldeUserNotification: (state, action) => {
      if (action.payload && ['user_new_notification', 'user_all_data_notification'].includes(action.payload.event)) {

        if (action.payload.event === 'user_new_notification') {
          const old_notif = state.notifications
          if (old_notif.length > 9) {
            old_notif.splice(8, 1)
          }
          old_notif.unshift(action.payload.data)
          state.notifications = old_notif
        } else if (action.payload.event === "user_all_data_notification") {
          state.notifications = action.payload.data.notifications
        }

      }
    },
    readUserNotification: (state) => {
      state.notifications = state.notifications.map(item => { return { ...item, is_read: true } })
    }
  }
})

export const { handleLogin, handleLogout, updateUserData, hanldeUserNotification, handleSocketConnect, readUserNotification } = authSlice.actions

export default authSlice.reducer
