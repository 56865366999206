// ** Reducers Imports
import layout from './layout'
import auth from './authentication'
import chat from '@src/views/chat/store'
import users from '@src/views/user/store'
import contacts from '@src/views/contacts/store'
import templates from '@src/views/template/store'
import broadcasts from '@src/views/broadcast/store'
import tags from '@src/views/tag/store'
import quickres from '@src/views/quick_response/store'
import webhooks from '@src/views/webhook/store'
import analytics from '@src/views/analytics/store'
import activity from '@src/views/pages/user_activity/store'

const rootReducer = {
  chat,
  auth,
  users,
  layout,
  contacts,
  templates,
  broadcasts,
  tags,
  quickres,
  webhooks,
  analytics,
  activity

}

export default rootReducer
