// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ToastError, ToastSuccess } from '@components/toaster'
import useJwt from '@src/auth/jwt/useJwt'

export const getTagList = createAsyncThunk('appTags/getTagList', async params => {
  if (params.q.length > 0) useJwt.handleCancelRequest()
  const response = await useJwt.GetTagApi(params.page, params.perPage, params.q)
  return {
    total: response.data.total,
    params,
    data: response.data.data
  }
})

export const addTag = createAsyncThunk('appTags/addTag', async (obj, { dispatch, getState }) => {
  const response = await useJwt.CreateTagApi(obj)
  if (response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    await dispatch(getTagList(getState().tags.params))
  } else {
    ToastError(null, response.data.message, "TR")
  }
  return user

})

export const deleteTag = createAsyncThunk('appTags/deleteTag', async (tag_id, { dispatch, getState }) => {
  const response = await useJwt.DeleteTagApi(tag_id)
  if (response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    await dispatch(getTagList(getState().tags.params))
  } else {
    ToastError(null, response.data.message, "TR")
  }
  return tag_id
})

export const appTagsSlice = createSlice({
  name: 'appTags',
  initialState: {
    data: [],
    total: 1,
    params: {},
    tableLoading: false,
    selectedUser: null
  },
  reducers: {
    setTableLoading: (state) => {
      state.tableLoading = true
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTagList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.total
        state.tableLoading = false
      })
  }
})

export const { setTableLoading } = appTagsSlice.actions
export default appTagsSlice.reducer
