// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ToastError, ToastSuccess } from '@components/toaster'
// ** Axios Imports
import axios from 'axios'
import useJwt from '@src/auth/jwt/useJwt'

export const getUserList = createAsyncThunk('appUsers/getUserList', async params => {
  if (params.q.length > 0) useJwt.handleCancelRequest()
  const response = await useJwt.UserListApi(params.page, params.perPage, params.q)
  if (response && response.data.success) {
    return {
      total: response.data.total,
      params,
      data: response.data.data
    }
  }
  return null
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get('/api/users/user', { id })
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user) => {
  const response = await useJwt.AddUserApi(user)
  if (response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response.data.message, "TR")
    return null
  }
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (user) => {
  const response = await useJwt.UpdateUserApi(user)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response.data.message, "TR")
    return null
  }
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    tableLoading: false,
    submitLoading: false,
    selected: null,
    modalOpen: false,
    modalType: "Create"
  },
  reducers: {
    setSelected: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setTableLoading: (state) => {
      state.tableLoading = true
    },
    setSubmitLoading: (state) => {
      state.submitLoading = true
    },
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen
      if (!state.modalOpen) {
        state.selected = null
        state.modalType = "Create"
      }
    },
    setModalType: (state, action) => {
      state.modalType = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserList.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
        }
        state.tableLoading = false
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selected = action.payload
      })
      .addCase(addUser.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = [action.payload, ...state.data]
          state.modalOpen = !state.modalOpen
          state.selected = null
          state.modalType = "Create"
        }
        state.submitLoading = false
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        if (action.payload) {
          const updatedData = state.data.filter(item => item.id !== action.payload.id)
          state.data = [action.payload, ...updatedData]
          state.modalOpen = !state.modalOpen
          state.selected = null
          state.modalType = "Create"
        }
        state.submitLoading = false
      })
  }
})
export const { setTableLoading, setSelected, toggleModal, setModalType, setSubmitLoading } = appUsersSlice.actions

export default appUsersSlice.reducer
