// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { ToastError, ToastSuccess } from '@components/toaster'
import useJwt from '@src/auth/jwt/useJwt'

export const getQuickResponseList = createAsyncThunk('appTags/getQuickResponseList', async params => {
  if (params.q.length > 0) useJwt.handleCancelRequest()
  const response = await useJwt.GetQuickResponseApi(params.page, params.perPage, params.q)
  if (response && response.data.success) {
    return {
      total: response.data.total,
      params,
      data: response.data.data
    }
  } else {
    return null
  }
})

export const addQuickResponse = createAsyncThunk('appTags/addQuickResponse', async (obj) => {
  const response = await useJwt.CreateQuickResponseApi(obj)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response.data.message, "TR")
    return null
  }
})

export const updateQuickResponse = createAsyncThunk('appTags/updateQuickResponse', async (obj) => {
  const response = await useJwt.UpdateQuickResponseApi(obj)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response.data.message, "TR")
    return null
  }

})

export const deleteQuickResponse = createAsyncThunk('appTags/deleteQuickResponse', async (id) => {
  const response = await useJwt.DeleteQuickResponseApi(id)
  if (response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return id
  } else {
    ToastError(null, response.data.message, "TR")
    return null
  }
})

export const appQResponse = createSlice({
  name: 'appQResponse',
  initialState: {
    data: [],
    total: 1,
    params: {},
    modalOpen: false,
    modalType: "Create",
    tableLoading: false,
    submitLoading: false,
    selected: null
  },
  reducers: {
    setTableLoading: (state) => {
      state.tableLoading = true
    },
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen
    },
    setModalType: (state, action) => {
      state.modalType = action.payload
    },
    setSelected: (state, action) => {
      state.selected = action.payload
    },
    setSubmitLoading: (state) => {
      state.submitLoading = true
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getQuickResponseList.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
          state.tableLoading = false
        }
      }).addCase(addQuickResponse.fulfilled, (state, action) => {
        if (action.payload) {
          state.total += 1
          state.data = [action.payload, ...state.data]
          state.modalOpen = !state.modalOpen
        }
        state.submitLoading = false
      }).addCase(updateQuickResponse.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = state.data.map(item => {
            if (item.id === action.payload.id) {
              return action.payload
            }
            return item
          })
          state.modalOpen = !state.modalOpen
        }
        state.submitLoading = false
      }).addCase(deleteQuickResponse.fulfilled, (state, action) => {
        if (action.payload) {
          state.total -= 1
          state.data = state.data.filter(item => item.id !== action.payload)
          state.modalOpen = !state.modalOpen
        }
        state.submitLoading = false
      })
  }
})

export const { setTableLoading, toggleModal, setSelected, setModalType, setSubmitLoading } = appQResponse.actions
export default appQResponse.reducer
