// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'
import { ToastError, ToastSuccess } from '@components/toaster'

export const getTemplateList = createAsyncThunk('templates/getTemplateList', async params => {
  if (params.q.length > 0) useJwt.handleCancelRequest()
  const response = await useJwt.TemplateListApi(params.page, params.perPage, params.q, params.status)
  if (response && response.data.success) {
    return {
      total: response.data.total,
      params,
      data: response.data.data
    }
  }
  return null
})

export const getTemplate = createAsyncThunk('templates/getTemplate', async id => {
  const response = await useJwt.BroadcastApi(id)
  if (response && response.data.success) {
    return response.data.data
  } else {
    return null
  }
})

export const addTemplate = createAsyncThunk('templates/addTemplate', async (data) => {
  const response = await useJwt.CreateTemplateMessageApi(data)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response.data.message, "TR")
    return null
  }
}
)

export const updateTemplate = createAsyncThunk('templates/updateTemplate', async (data) => {
  const response = await useJwt.UpdateTemplateMessageApi(data)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response.data.message, "TR")
    return null
  }

}
)

export const deleteTemplate = createAsyncThunk('templates/deleteTemplate', async id => {
  const response = await useJwt.DeleteTemplateApi(id)
  if (response && response.data.success) {
    ToastSuccess(null, response.data.message, "TR")
    return response.data.data
  } else {
    ToastError(null, response.data.message, "TR")
    return null
  }
})

export const templatesSlice = createSlice({
  name: 'templates',
  initialState: {
    data: [],
    total: 1,
    params: {},
    selected: null,
    tableLoading: false,
    modalType: "Create", //create | view | edit | Delete
    modalOpen: false,
    submitLoading: false
  },
  reducers: {
    toggleModal: (state) => {
      state.modalOpen = !state.modalOpen
      if (!state.modalOpen) {
        state.selected = null
        state.modalType = "Create"
      }
    },
    toggleModalType: (state, action) => { state.modalType = action.payload },
    setTableLoading: (state) => { state.tableLoading = true },
    setSelected: (state, action) => { state.selected = action.payload },
    setSubmitLoading: (state) => { state.submitLoading = true }
  },
  extraReducers: builder => {
    builder.addCase(getTemplateList.fulfilled, (state, action) => {
      if (action.payload) {
        state.data = action.payload.data
        state.total = action.payload.total
        state.params = action.payload.params
      }
      state.tableLoading = false
    }).addCase(getTemplate.fulfilled, (state, action) => {
      if (action.payload) {
        state.selected = action.payload
      }
    })
      .addCase(addTemplate.fulfilled, (state, action) => {
        if (action.payload) {
          state.total = state.total + 1
          state.data = [action.payload, ...state.data]
          state.modalOpen = !state.modalOpen
        }
        state.submitLoading = false
      }).addCase(updateTemplate.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = state.data.map(item => {
            if (item.id === action.payload.id) {
              return action.payload
            }
            return item
          })
          state.modalOpen = !state.modalOpen
        }
        state.submitLoading = false

      }).addCase(deleteTemplate.fulfilled, (state, action) => {
        if (action.payload) {
          state.total = state.total - 1
          state.data = state.data.filter(item => item.id !== action.payload)
          state.modalOpen = !state.modalOpen
          state.selected = null
          state.modalType = "Create"
        }
        state.submitLoading = false
      })
  }
})

export const { setSelected, setTableLoading, toggleModal, toggleModalType, setSubmitLoading } = templatesSlice.actions

export default templatesSlice.reducer
