// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

export const getAnalytics = createAsyncThunk('appTags/getAnalytics', async params => {
  const response = await useJwt.AnalyticsApi(params.from_date, params.to_date)
  return {
    params,
    data: response.data.data
  }
})

export const appAnalyticsSlice = createSlice({
  name: 'appAnalytics',
  initialState: {
    data: null,
    params: {},
    tableLoading: false
  },
  reducers: {
    setTableLoading: (state) => {
      state.tableLoading = true
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.tableLoading = false
      })
  }
})

export const { setTableLoading } = appAnalyticsSlice.actions
export default appAnalyticsSlice.reducer
