// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

export const getUserActivity = createAsyncThunk('appTags/getUserActivity', async params => {
  if (params.q.length > 0) useJwt.handleCancelRequest()
  const response = await useJwt.getUserActivity(params.page, params.perPage, params.q)
  if (response && response.data.success) {
    return {
      total: response.data.total,
      params,
      data: response.data.data
    }
  }
  return null
})

export const appUserActivitySlice = createSlice({
  name: 'appUserActivity',
  initialState: {
    data: [],
    total: 1,
    params: {},
    tableLoading: false,
    selectedUser: null
  },
  reducers: {
    setTableLoading: (state) => {
      state.tableLoading = true
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserActivity.fulfilled, (state, action) => {
        if (action.payload) {
          state.data = action.payload.data
          state.params = action.payload.params
          state.total = action.payload.total
        }
        state.tableLoading = false
      })
  }
})

export const { setTableLoading } = appUserActivitySlice.actions
export default appUserActivitySlice.reducer
