import NotSound from '@src/assets/audio/notification_sound.mp3'
// import jwtDecode from 'jwt-decode'
import moment from 'moment'
import useJwt from '@src/auth/jwt/useJwt'
const config = useJwt.jwtConfig
import XLSX from 'xlsx'
import fernet from "fernet"
import Avatar from '@components/avatar'
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0
// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
export const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem(config.storageTokenKeyName)

export const decodeUserData = () => {
  const token = localStorage.getItem('__enud')
  if (token) {
    try {
      const key = process.env.REACT_APP_FERNET_KEY
      const secret = new fernet.Secret(key)
      const FERNET = new fernet.Token({
        secret,
        token,
        ttl: 0
      })
      const result = FERNET.decode()
      return JSON.parse(result)
    } catch (err) {
      console.log("error invalid token")
      return null
    }
  }
  return null
}

export const isPlanExpired = (userData = null) => {
  if (userData) {
    if (userData.subscription.status === "Trial") {
      return moment() > moment(moment.utc(userData.subscription.trial_end).toDate())
    }
    return moment() > moment(moment.utc(userData.subscription.subscribe_renew).toDate())
  }
  return true
}


/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'Owner' || userRole === "Member" || userRole === "Admin") return '/chat'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const sendingSvg = <svg viewBox="0 0 16 15" width="16" height="15" ><path fill="gray" d="M9.75 7.713H8.244V5.359a.5.5 0 0 0-.5-.5H7.65a.5.5 0 0 0-.5.5v2.947a.5.5 0 0 0 .5.5h.094l.003-.001.003.002h2a.5.5 0 0 0 .5-.5v-.094a.5.5 0 0 0-.5-.5zm0-5.263h-3.5c-1.82 0-3.3 1.48-3.3 3.3v3.5c0 1.82 1.48 3.3 3.3 3.3h3.5c1.82 0 3.3-1.48 3.3-3.3v-3.5c0-1.82-1.48-3.3-3.3-3.3zm2 6.8a2 2 0 0 1-2 2h-3.5a2 2 0 0 1-2-2v-3.5a2 2 0 0 1 2-2h3.5a2 2 0 0 1 2 2v3.5z"></path></svg>

export const sentSvg = <svg viewBox="0 0 16 15" width="16" height="15" ><path fill="currentColor" d="m10.91 3.316-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>
export const deliveredSvg = <svg viewBox="0 0 16 15" width="16" height="15" >
  <path fill="currentColor" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z">
  </path>
</svg>

export const readSvg = <svg viewBox="0 0 16 15" width="16" height="15" ><path fill="#0400ee" d="m15.01 3.316-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"></path></svg>

export const failedSvg = <svg viewBox="0 0 24 24" width="16" height="15" ><path fill="#ea5455" d="M12 4a8 8 0 1 0 0 16 8 8 0 0 0 0-16zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"></path><path fill="#ea5455" d="M12 14a1 1 0 0 1-1-1V7a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1zm-1.5 2.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0z"></path></svg>

export const handleMessageStatus = (status) => {
  if (status === "Sending") {
    return sendingSvg
  } else if (status === "Sent") {
    return sentSvg
  } else if (status === "Delivered") {
    return deliveredSvg
  } else if (status === "Read") {
    return readSvg
  } else if (status === "Failed") {
    return failedSvg
  } else {
    return sentSvg
  }
}

export const PlaySound = () => {
  document.title = "New Message !"
  setTimeout(() => {
    document.title = "InteliChat"
  }, 1000)

  const audioElement = new Audio()
  audioElement.src = NotSound
  audioElement.play()
}

export const convertUTCDateToLocalDate = (date) => {
  if (date) {
    date = new Date(date)
    const localDate = moment.utc(date).local().format()
    return new Date(localDate)
  }
  return null
}

export const TableDateFormat = (date, format = 'DD/MM/YYYY hh:mm a') => {
  if (date) {
    date = new Date(date)
    const localDate = moment.utc(date).local().format(format)
    return localDate
  }
  return null
}

export const kmbFormater = (value) => {
  const suffixes = ["", "k", "m", "b", "t"]
  const suffixNum = Math.floor(value.toString().length / 3)
  let shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(2))
  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1)
  }
  return shortValue + suffixes[suffixNum]
}


export const handleStatus = (status) => {
  if (status === "New") {
    return 'new'
  } else if (status === "Open") {
    return 'away'
  } else if (status === "Broadcast") {
    return 'offline'
  } else if (status === "Resolved") {
    return 'online'
  } else {
    return 'busy'
  }
}

export const handleBadgeColor = (status) => {
  if (status === "New") {
    return 'info'
  } else if (status === "Open") {
    return 'warning'
  } else if (status === "Broadcast") {
    return 'secondary'
  } else if (status === "Resolved") {
    return 'success'
  } else {
    return 'danger'
  }
}

export const CopyToClipboard = async (text) => {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}


export const GetDocIcon = (file_name) => {
  const ext_name = file_name.split('.').pop().toLowerCase()
  if (ext_name === "pdf") {
    return require('@src/assets/images/fileicon/pdf.png').default
  } else if (ext_name === "txt") {
    return require('@src/assets/images/fileicon/txt.png').default
  } else if (ext_name === "svg") {
    return require('@src/assets/images/fileicon/svg.png').default
  } else if (ext_name === "csv") {
    return require('@src/assets/images/fileicon/csv.png').default
  } else if (ext_name === "docx") {
    return require('@src/assets/images/fileicon/doc.png').default
  } else if (ext_name === "xlsx") {
    return require('@src/assets/images/fileicon/xls.png').default
  } else if (ext_name === "pptx") {
    return require('@src/assets/images/fileicon/ppt.png').default
  } else if (ext_name === "apk") {
    return require('@src/assets/images/fileicon/apk.png').default
  } else {
    return require('@src/assets/images/fileicon/default.png').default
  }
}

export const downloadXLSX = (data, name) => {
  if (data.length > 0) {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "People")
    XLSX.writeFile(wb, `${name}.xlsx`)
  }
}

export const WABASupportData = (type, size) => {
  if (['audio/aac', 'audio/mp4', 'audio/amr', 'audio/mpeg', 'audio/ogg'].find((item) => item === type)) {
    if (size > 16 * (1024 ** 2)) return false
    return true
  } else if (['video/mp4', 'video/3gpp'].find((item) => item === type)) {
    if (size > 16 * (1024 ** 2)) return false
    return true
  } else if (['image/jpeg', 'image/png', 'image/jpg'].find((item) => item === type)) {
    if (size > 5 * (1024 ** 2)) return false
    return true
  } else if (['image/webp'].find((item) => item === type)) {
    if (size > 100 * 1024) return false
    return true
  } else {
    if (size > 100 * (1024 ** 2)) return false
    return true
  }
}

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]})`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}

export const convertBIS = (msg) => {
  const bold_regex = /(\*\*?)(?![\s\*])((?:[\s*]*(?:\\[\\*]|[^\\\s*]))+?)\1/gm
  const italic_regex = /(\_\_?)(?![\s\_])((?:[\s_]*(?:\\[\\_]|[^\\\s_]))+?)\1/gm
  const strike_regex = /(\~\~?)(?![\s\~])((?:[\s~]*(?:\\[\\~]|[^\\\s~]))+?)\1/gm
  const mono_regex = /(?:```)(?!\s)(?:(?!\n|```).)+(?:```)/gm
  const bold_replace = `<b>$2</b>`
  const italic_replace = `<i>$2</i>`
  const strike_replace = `<s>$2</s>`
  const mono_replace = `<tt>$2</tt>`
  const result = msg.replace(bold_regex, bold_replace).replace(italic_regex, italic_replace).replace(strike_regex, strike_replace).replace(mono_regex, mono_replace)
  return <span dangerouslySetInnerHTML={{ __html: result }} />
}

export const FilterWordColor = (string, query) => {
  const regex = new RegExp(query, "gim")
  const result = string.replace(regex, (match) => `<strong class='text-primary'>${match}</strong>`)
  return <span dangerouslySetInnerHTML={{ __html: result }} />
}


export const renderUserAvtar = (row, whlen = '20px', fsize = "0.5rem") => {
  const stateNum = Math.floor(Math.random() * 6),
    states = ['light-success', 'light-danger', 'light-warning', 'light-info', 'light-primary', 'light-secondary'],
    color = states[stateNum]
  if (row.avatar) {
    return <Avatar img={row.avatar.url} imgHeight={whlen} imgWidth={whlen} />
  } else {
    return <Avatar color={color || 'primary'} contentStyles={{ width: whlen, height: whlen, fontSize: fsize }} content={row.name || 'John Doe'} initials />
  }
}

export const IsConversationOpen = (date) => {
  if (date) {
    date = new Date(date)
    return moment.utc(date).local() > new Date()
  }
  return false
}


export const MatchingCharSearch = (string, query) => {
  if (query) {
    const regex = new RegExp(query, "gim")
    const result = string.replace(regex, (match) => `<strong class='text-primary'>${match}</strong>`)
    return convertBIS(result)
  }
  return string
}

export const timeSince = (date) => {
  const seconds = Math.floor((new Date() - convertUTCDateToLocalDate(date)) / 1000)
  let interval = seconds / 31536000
  if (interval > 1) {
    return `${Math.floor(interval)} years ago`
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return `${Math.floor(interval)} months ago`
  }
  interval = seconds / 86400
  if (interval > 1) {
    return `${Math.floor(interval)} days ago`
  }
  interval = seconds / 3600
  if (interval > 1) {
    return `${Math.floor(interval)} hours ago`
  }
  interval = seconds / 60
  if (interval > 1) {
    return `${Math.floor(interval)} minutes ago`
  }
  return `${Math.floor(seconds)} seconds ago`
}
